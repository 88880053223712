import apiConfig from "./apiConfig";
import axios from "axios";
// import GlobalData from "./GlobalData";

export default class ApiRequest {

  static set = async (endpoint, method, body, headers) => {
    let response = await fetch(endpoint.includes('http') ? endpoint : apiConfig.base_url + endpoint, {
      method: method,
      headers: headers ? headers :
        {
          'Content-Type': 'application/json',
          Authorization : localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null,
          Accept: 'application/json',
        },
      body: JSON.stringify(body)
    });

    console.log("token" + localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null)

    if (response.ok) {
      return response.json()
    } else {
      let error = await response.json()

      if(error.code === "JWT_EXPIRED"){
        localStorage.removeItem('token')
        localStorage.removeItem('gender')
        localStorage.removeItem('fullname')
        window.location = "/"
      }

      console.log(error)

      throw error
    }
  }
}
