import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../assets/logo.png"
import Button from "react-bootstrap/Button";
import styles from "../values/styles";
import {Link} from "react-router-dom";
import CustomButton from "./Reusable/CustomButton";
import {scroller} from 'react-scroll'
import Swal from "sweetalert2";
import Palette from "../utils/Palette";
import {GiHamburgerMenu} from "react-icons/gi";
import {FaDoorOpen} from "react-icons/fa";
import {MdMenu} from "react-icons/all";

function Header(props) {
  const {
    clearMode,
    onHeaderChosen
  } = props

  // console.log('activeHeader', activeHeader)

  const scrollTo = (to) => {
    scroller.scrollTo(to, {
      duration: 100,
      delay: 0,
      smooth: true,
      offset: -100
    })
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [navToogle, setNavToogle] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState("HOME")

  useEffect(()=>{
    // alert("terpanggil")
    onHeaderChosen(selectedMenu)
  },[selectedMenu])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const logoutButton = () => {
    return (localStorage.getItem('token') &&
      <CustomButton
        onClick={() => {

          localStorage.removeItem('token')
          localStorage.removeItem('gender')
          localStorage.removeItem('fullname')
          window.location = "/"
        }}
        style={{
          ...styles.boldText,
          width: 120,
          marginLeft: 5
        }}
        size={"sm"}>
        Logout
      </CustomButton>)
  }

  const isMobile = windowWidth < 768

  return (
    <Container fluid style={{
      position: 'fixed',
      top: 0, zIndex: 101,
      width: "100%",
      background: clearMode ? "#00000000" : "#fbfafd"
    }}>
      <Row style={{
        boxShadow: clearMode ? null : 'rgb(136, 136, 136) 0px -2px 3px 1px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        backgroundColor: clearMode ? "#00000000" : 'white',
        background: "#fbfafd30",
        // height: 76,
        padding: 5
      }}>
        <Col xs={12} md={3} style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }}
        >
          <Container>
            <Row>
              <Col xs={6}>
                <a
                  style={{
                    // flex: 1,
                    // display: "flex",
                    alignItems: "flex-start"
                  }}
                  href={"/"}
                >

                  <div style={{
                    padding: 5,
                    borderRadius: 10
                  }}>
                    <img
                      src={logo}
                      style={{
                        height: 50,
                        paddingTop: 10,
                        paddingBottom: 5,
                        flex: 1,
                        objectFit: "contain"
                      }}/>
                  </div>


                </a>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                xs={6}>
                {
                  isMobile &&
                  <MdMenu size={24}/>
                }

              </Col>
            </Row>
          </Container>
        </Col>
        {
          !isMobile &&
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              fontFamily: "syne",
              color : "#004283",
              // background : "red"
              maxWidth : 1200
            }}
            md={7}>
            <div
              className={"d-flex align-items-center justify-content-center"}
              onClick={()=>{setSelectedMenu("HOME")}}
              style={{flex : 1, cursor : "pointer", fontWeight : selectedMenu === "HOME" ? 800 : 400}}>
              HOME
            </div>
            <div
              className={"d-flex align-items-center justify-content-center"}
              onClick={()=>{setSelectedMenu("PROBLEM")}}
              style={{flex : 1, cursor : "pointer", fontWeight : selectedMenu === "PROBLEM" ? 800 : 400}}>
              PROBLEM
            </div>
            <div
              className={"d-flex align-items-center justify-content-center"}
              onClick={()=>{setSelectedMenu("SOLUTION")}}
              style={{flex : 1, cursor : "pointer", fontWeight : selectedMenu === "SOLUTION" ? 800 : 400}}>
              SOLUTION
            </div>
            <div
              className={"d-flex align-items-center justify-content-center"}
              onClick={()=>{setSelectedMenu("PRODUCT")}}
              style={{flex : 1, cursor : "pointer", fontWeight : selectedMenu === "PRODUCT" ? 800 : 400}}>
              PRODUCT
            </div>
            <div
              className={"d-flex align-items-center justify-content-center"}
              onClick={()=>{setSelectedMenu("CONTACT US")}}
              style={{flex : 1, cursor : "pointer", fontWeight : selectedMenu === "CONTACT US" ? 800 : 400}}>
              CONTACT US
            </div>
          </Col>
        }
      </Row>

    </Container>
  );
}

export default Header;
