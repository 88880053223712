import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import React, {useState} from "react";
import Header from "./components/Header";
import Container from "react-bootstrap/Container";

import 'react-ig-feed/dist/index.css'

import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom"
import LandingPageV9 from "./components/LandingPageV9";
import RequestDelete from './components/RequestDelete';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-213169882-1', {
    debug: true,
    titleCase: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    const [isLoginShown, setLoginShown] = useState(false)
    const [isRegisterShown, setRegisterShown] = useState(false)

    return (
        <Container fluid style={{
            padding: 0,
            overflowX: "hidden"
        }}>

            <HashRouter>
            {/*<BrowserRouter>*/}
                <Switch>
                    <Route exact path={"/request-delete"} component={RequestDelete}/>
                    <Route path={"/"} component={LandingPageV9}/>
                    {/* <Route exact path={"/v2"} component={LandingPageV2}/>
                    <Route exact path={"/v3"} component={LandingPageV3}/>
                    <Route exact path={"/v4"} component={LandingPageV4}/>
                    <Route exact path={"/v5"} component={LandingPageV5}/>
                    <Route exact path={"/v6"} component={LandingPageV6}/>
                    <Route exact path={"/v7"} component={LandingPageV7}/>
                    <Route exact path={"/v8"} component={LandingPageV8}/>
                    <Route exact path={"/v9"} component={LandingPageV9}/> */}
                </Switch>
            {/*</BrowserRouter>*/}
            </HashRouter>


        </Container>
    );

}

export default App;
