
import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';


import Header from "./Header";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Container, Row, Col, Button } from "react-bootstrap";
import Palette from "../utils/Palette";

import Swal from "sweetalert2"

let requestCountDownTime = 0

export default function RequestDelete(props) {



  return <Container
    style={{
      paddingTop : 20
    }}
  >
    <Row>
      <Col>
        <h2>Account Delete Form</h2>
        <Form.Label>Hi, we are sorry to hear that you are going to remove your account from our application. Please fill in the form below to confirm the deletion </Form.Label>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Please enter your email" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Delete Reason</Form.Label>
            <Form.Control as="textarea" placeholder="Please enter the reason to delete your account"  rows={3} />
          </Form.Group>
        </Form>
        <Button 
          onClick={async()=>{
            let result = await Swal.fire({
              title : "Are you sure", 
              text : "Are you sure you want to delete your account?",
              icon : "info",
              showCancelButton : true,
              cancelButtonText : "No",
              confirmButtonText : "Yes",
            })
            if(result.isConfirmed){

            }
          }}
          style={{background : Palette.WELL_BLUE, borderColor : Palette.WELL_BLUE, color :"white"}}>Submit</Button>
      </Col>
    </Row>

  </Container>

}
